/**
 * External Dependencies
 */
import 'jquery';
import AOS from 'aos';
import 'slick-carousel';
import 'sharer.js';
import 'bootstrap';

$(document).ready(() => {
  AOS.init({
    once: true,
  });

  $('.section-header-card .card__primary').each(function () {
    if ($(window).width() > 768) {
      setPosition();
    }
    $(window).on('resize', function () {
      if ($(window).width() > 768) {
        setPosition();
      }
    });

    function setPosition() {
      const $card = $(this);
      const $title = $card.find('h2');

      const titleHeight = $title.height();
      const bodyHeight = $card.find('.card-body').height();

      setTimeout(function () {
        $card
          .find('.card-body')
          .css(
            'transform',
            'translateY(' + Math.round(bodyHeight - titleHeight + 10) + 'px)'
          );
      }, 400);
    }
  });

  $(window)
    .on('resize', function () {
      $('.section-blog, .section-expert').each(function () {
        const $this = $(this);
        const marginRight = ($(window).width() - $('.container').width()) / 2;
        const $slider = $this.find('.slider');

        if ($(window).width() > 992) {
          $slider.css('margin-right', -marginRight);
          $this.find('.slick-list').css('padding-right', marginRight);
        }
      });
    })
    .trigger('resize');

  $('.slider').on('init', function () {
    $('.section-blog, .section-expert, .section-client').each(function () {
      const $this = $(this);
      const $slider = $this.find('.slider');

      $this.on('click', '.btn', function () {
        if ($(this).hasClass('btn-prev')) {
          $slider.slick('slickPrev');
        } else {
          $slider.slick('slickNext');
        }
      });

      setTimeout(function () {
        $(window).trigger('resize');
      });
    });
  });

  // put video outside the container with negative margin
  $('.section-about').each(function () {
    const $this = $(this);
    $(window).on('resize', function () {
      aboutVideo($this);
    });
    function aboutVideo($this) {
      const $iframe = $this.find('.embed-responsive');

      if ($(window).width() > 992) {
        const marginRight = ($(window).width() - $('.container').width()) / 2;

        $iframe.css('margin-right', -marginRight);
      } else {
        $iframe.css('margin-right', 0);
      }
    }
  });

  $('.section-steps').each(function () {
    $(this).on('click', '.svg-link', function () {
      const target = $(this).data('target');
      const position = $(target).offset().top;
      $('html,body').animate({ scrollTop: position - 400 }, 200);
    });
  });

  setTimeout(function () {
    $('.slider-blog').slick({
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    });
  }, 100);

  $('.slider-expert').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $('.slider-client').slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: false,
    dots: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  });

  // card slider on mobile but not on desktop
  $('.section-header-text').each(function () {
    $(window).on('resize', function () {
      gerenateSlider();
    });
    gerenateSlider();

    function gerenateSlider() {
      let slickInit = false;

      if ($(window).width() < 992) {
        $('.section-header-text--slider').slick({
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        });

        slickInit = true;
      } else {
        if (slickInit == true) {
          $('.section-header-text--slider').slick('unslick');
          slickInit = false;
        }
      }

      $('.section-header-text').on('click', '.btn', function () {
        if ($(this).hasClass('btn-prev')) {
          $('.section-header-text--slider').slick('slickPrev');
        } else {
          $('.section-header-text--slider').slick('slickNext');
        }
      });
    }
  });

  // menu mobile animation
  $('#js-burger').each(function () {
    const $this = $(this);

    $this.on('click', function () {
      $this.toggleClass('active');
      $('#js-nav-mobile').toggleClass('active');
    });
  });

  // header btn to scroll down
  $('.section-header-image').each(function () {
    const $this = $(this);
    $this.find('#js-scroll-down').on('click', function () {
      const sectionHeight = $this.height();
      $('html,body').animate({ scrollTop: sectionHeight + 100 }, 200);
    });
  });

  // add bg on menu on scroll
  $(window)
    .on('scroll', function () {
      if ($(window).scrollTop() > 50) {
        $('body').addClass('scrolled');
      } else {
        $('body').removeClass('scrolled');
      }
    })
    .trigger('scroll');

  // filter on template-team
  $('#header-team').each(function () {
    $(this).on('click', '.btn', function () {
      const $this = $(this);
      const target = $(this).attr('data-target');

      $('#header-team').find('.btn-light').removeClass('active');

      setTimeout(function () {
        $this.addClass('active');
      });

      $('.card-filter').hide();
      $(target).show();
    });
  });
});
